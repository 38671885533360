class PersonManagerComponent extends Component {

    static name() {
        return "PersonManagerComponent";
    }

    static componentName() {
        return "PersonManagerComponent";
    }

    data() {
        return {
            isloading:false,
            customerPersons:[],
            onlyActived:false,
            columns: ['Avatar', 'Name', 'WebUser', 'WebAccessGroup', 'WebFlag', "Actions"],
            css: {
                ascendingIcon: 'blue chevron up icon',
                descendingIcon: 'blue chevron down icon',
                table: {
                    loadingClass: 'loading',
                    ascendingIcon: 'blue chevron up icon',
                    descendingIcon: 'blue chevron down icon',
                }
            },
        };
    }


    getComputed() {
        return  {
            getCustomerPersons:function(){

                this.customerPersons = this.$store.getters.getCustomerPersons;

                if(this.onlyActived)
                    return this.customerPersons.filter(x=>x.WebUserFlag == '1');
                return this.customerPersons.sort(function(x, y) {
                    return (x.WebUserFlag === y.WebUserFlag)? 0 : x.WebUserFlag ? -1 : 1;
                });
            },
            options: function ( h ) {
                return {
                    preserveState: true,
                    filterByColumn: false,
                    filterable: ["Name", "WebUser"],
                    headings: {
                        'Avatar': '',
                        'Name': this.tr('Name'),
                        'WebFlag': this.tr('Enabled'),
                        'WebUser': this.tr('User'),
                        'WebAccessGroup': this.tr('Web Access'),
                        'Actions': this.tr('Edit')
                    },
                    perPage: 10,
                    pagination: {
                        chunk: 15,
                    },
                    templates: {
                        Actions: 'CustomerPersonsActionsComponent',
                        Avatar: 'AvatarPersonsListComponent',
                        WebFlag: 'WebFlagComponent'
                    },
                    texts: {
                        count: `Showing {from} to {to} of {count} ${this.tr('Person')}|{count} ${this.tr('Persons')}|One ${this.tr('Person')}`,
                        filter: '',
                        limit: '',
                        filterPlaceholder: '',
                        noResults: this.tr('No Persons founds'),
                        page: this.tr('Page') + ":", // for dropdown pagination
                        filterBy: 'Filtrado por {column}', // Placeholder for search fields when filtering by column
                        loading: this.tr('Loading') + '...', // First request to server
                        defaultOption: 'Select {column}', // default option for list filters,
                    },
                    dateFormat: "DD-MM-YYYY",
                    uniqueKey: "Code"
                };
            },

        };
    }

    getWatch() {
        return {
            'onlyActived':function(){
                console.log('CAMBIO ', this.onlyActived);
            },

        };
    }

    mounted() {
        return function () {
            this.loadPersons();
        };
    }

    getMethods() {
        return {
            loadPersons: this.loadPersons,
            AddPerson:this.AddPerson,
        };
    }

    loadPersons () {
        //let self=this;
        this.isloading=true;
        this.$store.dispatch('loadCustomerPersons');
        this.isloading=false;

        // axios.get('/ecommerce/api/getPersonsAssociated')
        //     .then(function (response) {
        //         if (response.data.ok) {
        //             self.customerPersons = [];
        //             let customerPersonsList = [];
        //             if(response.data.persons.length>0) {
        //                 for (let p of response.data.persons) {
        //                     let clone_p = Object.assign({}, p);
        //                     clone_p.Name = clone_p.Name + ' ' + clone_p.LastName;
        //                     //Esto lo hago para q aparezca el nombre con los apellidos, y lo copio para q no me afecte el state
        //                     if(self.viewmode == 'active-persons') {
        //                         if (clone_p.WebUserFlag)
        //                             customerPersonsList.push(clone_p);
        //                     } else
        //                         customerPersonsList.push(clone_p);
        //                 }
        //             }
        //             self.customerPersons = customerPersonsList;
        //             self.$store.commit('loadCustomerPersons',response.data.persons);
        //         }
        //         self.isloading=false;
        //     })
    }

    async AddPerson () {
        //limpiar selects
        this.$store.dispatch('clearSelectsLinkto');
    }


    getTemplate() {
        return`<div  class="row" id="AdminPerson" >
                    <template v-if="isLoading">
                        <div class="row justify-content-center align-items-center" style="height:35rem;">
                                <h3 class="col-8" >
                                    <img src="../static/ecommerce/img/lg-spinner-gif.gif" >
                                    {{tr('Loading Report Data')}}
                                </h3>
                        </div>
                    </template>
                    <template v-else>
                        <PersonModalComponent> </PersonModalComponent>
                        <PersonEditModalComponent> </PersonEditModalComponent>
                        <closePersonConfirmModalComponent></closePersonConfirmModalComponent>
                        <div class="row justify-content-end" style="position: absolute;right:2rem;z-index: 999;">
                            <div class="btn-group-toggle" data-toggle="buttons">
                              <label class="btn btn-secondary" :class="{'active': onlyActived}" @click="onlyActived=!onlyActived">
                                    <template v-if="onlyActived">{{tr('See all')}}</template>
                                    <template v-else>{{tr('Only actives')}}</template> 
                              </label>
                            </div>
                            <div class="view-mode float-sm-right mb-3 ml-1 mr-2" >
                                <button type="submit" class="btn btn-custom" data-toggle="modal" data-target="#PersonModal" @click="AddPerson()"><i class="fas fa-user-plus fa-fw"></i> {{tr('Add Person')}}</button>
                            </div>
                        </div>
                        <div class="container center section white" v-if="isloading">
                             <div class="row col-12 justify-content-center align-items-center" style="min-height:15rem" >
                              <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                              </div>
                              <h3>
                                   {{tr('Page loading please wait')}}
                              </h3>
                             </div>
                        </div>
                        <v-client-table class="custom-vue-table" @rowclick="" :columns="columns" :data="getCustomerPersons" :options="options" :css="css" v-else>
                            <div slot="afterFilter" class="VueTables__search-field">
                               <div class="input-group-append">
                                    <span class="input-group-text" id="basic-addon2"><i class="icon fas fa-search"></i></span>
                              </div>
                            </div>
                        </v-client-table>
                    </template>
                </div>`;
    }
}

PersonManagerComponent.registerComponent();
